import $ from "jquery";

interface GalleryImage {
  node: JQuery;
  url: string;
}

declare const window: Window & {
  startGallery: (images: GalleryImage[]) => void;
};

(function () {
  /* Photo gallery*/
  (function () {
    let images: GalleryImage[] = [];
    let currentIndex = 0;
    const animationSpeed = 500;

    const redrawGallery = function () {
      images.forEach((img) => {
        if (img.node) {
          // Resize image to fit screen
          img.node.css({
            backgroundImage: "url('" + img.url + "')",
            backgroundPosition: "center top"
          });
        }
      });
    };
    $(window).bind("resize", redrawGallery);

    function showImage(index: number, skipHide?: boolean) {
      currentIndex = index;
      if (!skipHide) {
        $("#gallery .image").hide();
      }
      images[index].node.fadeIn(animationSpeed);
    }

    const showNextImage = function () {
      let nextIndex = currentIndex + 1;
      if (nextIndex >= images.length) {
        nextIndex = 0;
      }
      images[currentIndex].node.fadeOut(animationSpeed, function () {});
      showImage(nextIndex, true);
    };

    const showPreviousImage = function () {
      let prevIndex = currentIndex - 1;
      if (prevIndex < 0) {
        prevIndex = images.length - 1;
      }
      images[currentIndex].node.fadeOut(animationSpeed, function () {});
      showImage(prevIndex, true);
    };

    window.startGallery = function (newImages: GalleryImage[]) {
      images = newImages;
      if (images.length > 0) {
        // Create the DOM nodes
        const $container = $('<div id="gallery" />').appendTo("body");
        images.forEach((img) => {
          img.node = $('<div class="image"></div>').appendTo($container);
        });

        // Hook up the UI
        $(".gallery_navigation .next_image").click(showNextImage);
        $(".gallery_navigation .previous_image").click(showPreviousImage);

        redrawGallery();
        showImage(0);
      }
    };
  })();

  /* Scaled background images */
  $(document).ready(function () {
    $(window).bind("resize", function () {
      if ($("#background").data("background-image")) {
        $("#background").css({
          backgroundImage:
            "url('" + $("#background").data("background-image") + "')",
          backgroundPosition: "center top"
        });
      }
    });
    $(window).trigger("resize");
  });
})();
