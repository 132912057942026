import $ from "jquery";
import "../vendor/jquery.columnizer";

$(function () {
  $("#background").addClass("fadeIn");

  $(".autocol").each(function () {
    let i: number;
    let box, j, len;
    const $boxes = $(this).find(".box");
    const ref = $boxes.slice(0, $boxes.length - 1);
    for (i = j = 0, len = ref.length; j < len; i = ++j) {
      box = ref[i];
      const nodes = $(box).children();
      if (nodes.length > 0) {
        const lastNode = nodes.last().get(0);
        if (lastNode.tagName.match(/^h[\d]/i)) {
          $(lastNode).prependTo($boxes[i + 1]);
        }
      }
    }
  });

  $(".box, h1, h2, a.home").each(function () {
    $(this).addClass("anim");
    setTimeout(() => {
      $(this).addClass("seen");
    }, 500 * Math.random());
  });
});
